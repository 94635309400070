import React from "react";
import {graphql, Link} from "gatsby";
import {PostTemplateQuery} from "../../graphql-types";
import Layout from "components/Layout";
import {contentfulRenderer} from "../utils/contentfulRenderer";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import {parseFn} from "../utils/parseFn";
import AmazonBanner from "components/AmazonBanner";
import {Helmet} from "react-helmet";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookIcon,
    FacebookMessengerIcon
} from "react-share";

export const postTemplateQuery = graphql`
    query PostTemplate($id: String!) {
        contentfulEntry(id: {eq: $id}) {
            ... on ContentfulPost {
                title
                subtitle
                link
                referredPage
                value
                createdAt
                updatedAt
                image {
                    file {
                        url
                    }
                    title
                }
                content {
                    raw
                    references {
                        ... on ContentfulAsset {
                            __typename
                            title
                            file {
                                url
                            }
                            contentful_id
                        }
                    }
                }
            }
        }
    }
`;

interface RelatedPost {
    slug: string;
    title: string;
    image: string;
}

const PostTemplate: React.FC<
    PropsWithTranslationSheet & {
        data: PostTemplateQuery;
        pageContext: {
            slug: string;
            withAmazonBanner?: boolean;
            previousPost: RelatedPost | null;
            nextPost: RelatedPost | null;
        };
    }
> = ({data, pageContext: {slug, t, withAmazonBanner = false, previousPost, nextPost}}) => {
    const {
        title,
        subtitle,
        link,
        referredPage,
        value,
        createdAt,
        updatedAt,
        content,
        image: {
            title: imageTitle,
            file: {url}
        }
    } = data.contentfulEntry;

    return (
        <Layout
            t={t}
            isPost
            slug={slug}
            title={parseFn(t.POST_PAGE_TITLE)(title, value)}
            metaDescription={subtitle}
            cardImagePath={`https:${url}`} //url has //prefix
            datePublished={new Date(createdAt)}
            dateUpdated={new Date(updatedAt)}
        >
            {!withAmazonBanner && (
                <Helmet>
                    <link
                        rel="alternate"
                        href="https://darmowe-wyprawki.pl/darmowa-paczka-powitalna-od-amazon/"
                        hrefLang="pl"
                    />
                    <link
                        rel="alternate"
                        href="https://baby-boxes.com.ua/bezkoshtovnyi-bebi-boks-vid-amazon/"
                        hrefLang="uk"
                    />
                </Helmet>
            )}

            <div className="relative">
                <div className="absolute top-3 flex justify-end gap-3 w-full">
                    <FacebookShareButton
                        url={t.ROOT_URL + "/" + slug}
                        title={title}
                        quote={subtitle}
                    >
                        <FacebookIcon size={30} className={"rounded"} />
                    </FacebookShareButton>

                    <FacebookMessengerShareButton
                        url={t.ROOT_URL + "/" + slug}
                        appId={"142691081113133"}
                    >
                        <FacebookMessengerIcon size={30} className={"rounded"} />
                    </FacebookMessengerShareButton>
                </div>

                <main className="mt-10">
                    <div className="mb-4 md:mb-0 w-full mx-auto relative">
                        <div className="mb-4 px-4 lg:px-0">
                            <h1 className="text-4xl font-semibold text-gray-800 leading-tight">
                                {title}{" "}
                                <span className="font-bold text-4xl text-green-700">
                                    {t.OF_VALUE_PREFIX} {parseFn(t.VALUE_LABEL)(value)}
                                </span>
                            </h1>
                            <h2>{subtitle}</h2>
                        </div>

                        <img
                            loading="eager"
                            src={`https:${url}`}
                            className="w-full object-contain lg:rounded h-60"
                            alt={imageTitle}
                        />
                    </div>

                    <div className="prose mt-12">
                        <h3>{t.HOW_TO_GET_PACKAGE}</h3>
                    </div>

                    <div className="w-full text-center">
                        <a
                            className="plausible-event-name=Post+CTA+Click flex items-center h-12 w-max px-4 mt-12 text-xl font-bold text-white transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline hover:bg-green-600"
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t.GET_PACKAGE_CTA} ↗
                        </a>
                    </div>

                    {withAmazonBanner && (
                        <div className="mt-12">
                            <AmazonBanner t={t} />
                        </div>
                    )}

                    <div className="flex flex-col lg:flex-row lg:space-x-12">
                        <div className="prose post-container mt-12 text-gray-700 text-lg leading-relaxed w-full">
                            {contentfulRenderer(content)}
                        </div>
                    </div>
                </main>

                {(previousPost || nextPost) && (
                    <div className="mt-8 py-4">
                        <span className="font-bold text-xl ">{t.READ_FURTHER}</span>

                        <div className="flex justify-between mt-4 gap-4">
                            {previousPost && (
                                <Link
                                    to={"/" + previousPost.slug + "/"}
                                    className="group flex flex-1 flex-col overflow-hidden rounded-lg shadow-lg"
                                >
                                    <div className="flex-shrink-0">
                                        <img
                                            loading="lazy"
                                            className="h-20 w-full object-cover group-hover:brightness-125"
                                            src={previousPost.image}
                                            alt={previousPost.title}
                                        />
                                    </div>
                                    <div className="flex flex-1 flex-col justify-between bg-white p-2">
                                        <p className="text-md font-semibold text-gray-900">
                                            {previousPost.title}
                                        </p>
                                    </div>
                                </Link>
                            )}

                            {nextPost && (
                                <Link
                                    to={"/" + nextPost.slug + "/"}
                                    className="group flex flex-1 flex-col overflow-hidden rounded-lg shadow-lg"
                                >
                                    <div className="flex-shrink-0">
                                        <img
                                            loading="lazy"
                                            className="h-20 w-full object-cover group-hover:brightness-125"
                                            src={nextPost.image}
                                            alt={nextPost.title}
                                        />
                                    </div>
                                    <div className="flex flex-1 flex-col justify-between bg-white p-2">
                                        <p className="text-md font-semibold text-gray-900">
                                            {nextPost.title}
                                        </p>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default PostTemplate;
