import React from "react";
import {TranslationSheet} from "../i18n/TranslationSheet";

const AmazonBanner: React.FC<{t: TranslationSheet}> = ({t}) => {
    return (
        <a
            href={t.AMAZON_POST_HREF}
            className="plausible-event-name=Amazon+Banner+Click flex-col sm:flex-row flex h-48 sm:h-32 w-full justify-between items-center bg-indigo-100 rounded group border-2 border-indigo-400"
        >
            <div
                className="p-3 text-center sm:text-left sm:pl-8 font-medium text-2xl group-hover:underline"
                dangerouslySetInnerHTML={{__html: t.AMAZON_BANNER_TEXT}}
            />

            <img
                alt={"Amazon Baby Registry Logo"}
                className="h-16 mb-6 sm:m-0 sm:pr-8"
                src={"/amazon_baby_registry.png"}
            />
        </a>
    );
};

export default AmazonBanner;
